import React, { useState } from 'react';

import { Layout } from '../../../components/layouts';
import { SEO } from '../../../components/common';
import {
  MattressLateralSection,
  TerraMattressProductSection,
  TerraMattressIntroSection,
  TerraMattressBenefitSection,
  TerraMattressUpsellHeroSection,
  TerraMattressAccordionSection,
  TerraMattressReviewHeroSection,
  TerraMattressUpsellGridSection,
  WoosaPromiseIconGridSection,
  TerraMattressFirmnessSection,
  TerraMattressLayerSection,
  TerraMattressProductFixedBottomBar,
} from '../../../components/sections';

const TerraMattressPage: React.FC = () => {
  const [isPlusCover, setIsPlusCover] = useState(false);

  const handleIsPlusCoverChange = (isChecked: boolean) => {
    setIsPlusCover(isChecked);
  };
  return (
    <Layout>
      <SEO
        title="The Terra Mattress, Firm Natural Latex Mattress Singapore"
        description="Firm and supportive mattress made from 100% Natural Latex. Designed and tested for Singaporeans."
      />
      <TerraMattressProductSection
        isPlusCover={isPlusCover}
        onChangeIsPlusCover={handleIsPlusCoverChange}
      />
      <TerraMattressIntroSection />
      <TerraMattressFirmnessSection />
      <TerraMattressLayerSection isPlusCover={isPlusCover} />
      <TerraMattressBenefitSection />
      <TerraMattressUpsellHeroSection />
      <TerraMattressAccordionSection />
      <TerraMattressReviewHeroSection />
      <TerraMattressUpsellGridSection />
      <WoosaPromiseIconGridSection version="v2" />
      <MattressLateralSection version="v2" />
      <TerraMattressProductFixedBottomBar
        isPlusCover={isPlusCover}
        onChangeIsPlusCover={handleIsPlusCoverChange}
      />
    </Layout>
  );
};
export default TerraMattressPage;
